import { useState, useEffect } from "react";
import cn from "classnames";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { useStore } from "store";
import { MAP_TYPES } from "pages/constants";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { VisiblePostCodeType, StoreType } from "store/types";
import { DMDocumentFormats, DMDocumentNames } from "shared/constants";
import { LocationLatLngType } from "components/place-autocomplete/type";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { getSelectedTargetsAsString } from "components/audience-map/utils";
import { FlyerTypeSelect } from "components/FlyerTypeSelect";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { SuggestionStart } from "static/media";
import { styles } from "./styles";
import { useTargetSelector } from "../../hooks";
import { TargetSelectorArea } from "../../TargetSelectorsArea/TargetSelectorsArea";

const useStyles = makeStyles(() => styles);

interface EDDMEmptyStateProps {
  countryCode: string;
  disablePlaceAutocomplete: boolean;
  handleAutocompleteChanged: (newMarker: any) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setDrawingMode: (drawingMode: null | string) => void;
}

export const EDDMEmptyStateV2: React.FC<EDDMEmptyStateProps> = ({
  countryCode,
  disablePlaceAutocomplete,
  handleAutocompleteChanged,
  getFilteredPostCodes,
  setShouldAutoSave,
  setDrawingMode,
}) => {
  const posthog = usePostHog();
  const postHogTracker = new EmitAnalyticsEvent(posthog);
  const {
    user,
    campaign: { id: campaignId, flyerType },
    client: { name: clientName },
    updateCampaign,
    selectedTargets,
    updateSelectedTargets,
    setSuggestionsEnabled,
  }: StoreType = useStore();
  const [location, setLocation] = useState<LocationLatLngType | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectorsOptions, filteredSelectedOptions } = useTargetSelector({
    selectedTargets,
    updateSelectedTargets,
    setShouldAutoSave,
  });

  useEffect(() => {
    if (selectedTargets.length) {
      const selectedTargetsPayload = getSelectedTargetsAsString(selectedTargets);
      postHogTracker.run({
        eventName: "Used audience attributes",
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
        additionalPayload: {
          campaignId,
          audienceAttributes: selectedTargetsPayload,
        },
      });
    }
  }, [selectedTargets]);

  const submitDisabled = !location;

  const onPlaceChanged = (newLocation: LocationLatLngType) => {
    if (!newLocation) return;
    setLocation(newLocation);
  };

  const onSubmit = async (enableSuggestions?: boolean) => {
    if (submitDisabled) return;
    setSubmitting(true);

    postHogTracker.run({
      eventName: enableSuggestions
        ? "Clicked to get route suggestions"
        : selectedTargets?.length
        ? "Explore map with attributes"
        : "Clicked to explore map without attributes",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        ...(selectedTargets?.length ? { audienceAttributes: getSelectedTargetsAsString(selectedTargets) } : {}),
      },
    });

    await updateCampaignExtraData({
      campaignId,
      flyerType,
    });
    setSuggestionsEnabled(!!enableSuggestions);
    setDrawingMode(enableSuggestions ? null : MAP_TYPES.CIRCLE);
    handleAutocompleteChanged(location);
    setSubmitting(false);
  };

  const handleChangeFlyerType = (newFlyerType: PossibleFlyerType) => {
    postHogTracker.run({
      eventName: "Changed postcard format before map navigation",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        postcardFormat: DMDocumentNames[newFlyerType as keyof typeof DMDocumentNames],
      },
    });
    updateCampaign({ flyerType: newFlyerType });
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.choseLocationModal}>
        <span className={classes.title}>Define your audience</span>
        <span className={classes.text}>Refine your targeting before choosing specific campaign routes</span>
        <span className={classes.subtitle}>
          First location <span className={classes.mandatory}>*</span>
        </span>
        <PlaceAutocomplete
          countryCode={countryCode}
          disabled={disablePlaceAutocomplete}
          placeholder={"Enter a city, neighborhood, or specific address"}
          postCodeSearch={true}
          onPlaceChanged={onPlaceChanged}
          getFilteredPostCodes={getFilteredPostCodes}
          disableAutoClear
        />
        <span className={classes.subtitle}>Audience attributes</span>
        <TargetSelectorArea selectorsOptions={selectorsOptions} />
        <span className={classes.subtitle}>
          Postcard format <span className={classes.mandatory}>*</span>
        </span>
        <FlyerTypeSelect
          handleChangeFlyerType={handleChangeFlyerType}
          flyerType={flyerType as keyof typeof DMDocumentFormats}
          scrollable
        />
        <div className={classes.buttonContainer}>
          <button
            disabled={submitDisabled}
            className={cn(classes.button, { [classes.disabled]: submitDisabled })}
            onClick={() => onSubmit()}
          >
            Explore map
            {submitting && <CircularProgress size={15} className={classes.submittingIcon} />}
          </button>
          <button
            className={cn(classes.buttonSuggestion, {
              [classes.disabledSuggestion]: submitDisabled || !filteredSelectedOptions.length,
            })}
            onClick={() => onSubmit(true)}
            disabled={submitDisabled || !filteredSelectedOptions.length}
          >
            Get routes suggestion
            <SuggestionStart stroke="#fff" style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};
