/* eslint-disable react/no-unknown-property */
import { useState, useMemo, useEffect, useContext } from "react";
import CONFIG from "config/config";
import cn from "classnames";
import { Helmet } from "react-helmet";
import { toFixed3 } from "utils/formators";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { capitalize } from "lodash";
import { usePostHog } from "posthog-js/react";
import { FeatureFlagContext } from "context/featureFlagContext";

import ConfirmPopup from "pages/flyer/builder/drawer/components/ConfirmPopup";
import { authorization } from "module/auth";
import { SnackBarContext } from "components/snack-bar";
import Header from "components/header";
import { CAMPAIGN_ENUM_TYPES, USER_CAMPAIGN_ENUM_TYPES, DocumentNames, DMDocumentLabels } from "shared/constants";
import { FlyerStatus } from "pages/flyer/flyerTypePage/useGetDataForPage";
import { deleteRedirectedCampaign } from "utils/getRedirectedCampaigns";
import { DM_MIN_SELECTABLE_DATE } from "../../../../../constants";
import { AlertInvalidMissions } from "../../../../../components/audience-form/components/campaign-location/h2h-precise-locations/components/AlertInvalidMissions/AlertInvalidMissions.tsx";
import { convertNumber, convertNumberRounded, renderStatus } from "../../container/utils";
import { formatDate, customDateFormat } from "../../../../../utils/date";
import {
  EDDM_NAVIGATION,
  NAVIGATION,
  READABLE_CAMPAIGN_INTERNAL_STATUSES,
  CAMPAIGN_INTERNAL_STATUSES,
} from "../../../../constants";
import { useStore } from "../../../../../store";
import PrintIcon from "../../../../../static/media/print-icon";
import { CouponCode } from "../CouponCode";
import Modal from "../Modal";
import { style } from "./style";
import { SubmitAndPayButton, UploadFlyerSection } from "./components";

const hideOnNull = (obj, block) => {
  if (obj?.rate) {
    return block;
  }
  return null;
};

const displayOnCondition = (condition, block) => {
  if (condition) {
    return block;
  }
  return null;
};

const QuoteView = ({
  classes,
  isPaymentSkipped,
  stripeCouponCode,
  couponErrorMessage,
  setCouponErrorMessage,
  headerActions,
  hideBackButton,
  data,
  onSubmit,
  dates,
  refs,
  handlePrint,
  toAudience,
  isChecked,
  setIsChecked,
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  loading,
  handleViewDesignClick,
  isSubmitConfirmationModalOpen,
  setIsSubmitConfirmationModalOpen,
  onApplyCouponCode,
  detailedCost,
  handleDatesChange,
  pageState,
  campaignId,
  flyerInfo,
}) => {
  const posthog = usePostHog();
  const [couponCodeOpen, setCouponCodeOpen] = useState(false);

  const runSnackBar = useContext(SnackBarContext);

  useEffect(() => {
    if (stripeCouponCode) {
      setCouponCodeOpen(true);
    }
  }, [stripeCouponCode]);

  const {
    submitter,
    submittedBy,
    campaignName,
    campaignDuration,
    flyerType,
    flyerWeight,
    currencySign,
    clientName,
    updatedAt,
    internalStatus = CAMPAIGN_INTERNAL_STATUSES.DRAFT,
    isSubmitted,
    quote,
    country,
    channel,
    amountOff,
    percentOff,
    cityName,
    isDM,
    flyersAmount,
    costPerFlyer,
    couponType: couponTypeDB,
    staticCouponCode: staticCouponCodeDB,
    dynamicCouponCode: dynamicCouponCodeDB,
  } = data;
  const { distribution, permit, printing, travel, outfit, subtotal, tax, totalCost } = detailedCost;
  const { startDate } = dates;
  const { firstPageRef, secondPageRef } = refs;
  const user = authorization.getUser();
  const isClient = authorization.isClient();
  const status = useMemo(() => READABLE_CAMPAIGN_INTERNAL_STATUSES[internalStatus], [internalStatus]);
  const isShowWrongDatesModal = startDate.error && !isSubmitted;
  const {
    user: { isVerified },
  } = useStore();

  const { featureFlags } = useContext(FeatureFlagContext);
  const isSkipEnabled = featureFlags.isSkipEnabled;
  const isUS = country.code === "US";
  const isUK = country.code === "UK";
  const defaultCampaignLimits = isDM ? DM_MIN_SELECTABLE_DATE : country.countryDefaultSettings.defaultCampaignLimits;
  const isD2D = channel === CAMPAIGN_ENUM_TYPES.LETTERBOX;
  const showCoupon = (!isSubmitted || !!stripeCouponCode) && !isPaymentSkipped;
  const notExistingDesignFile = pageState === FlyerStatus.flyerAbsent || !flyerInfo.s3Link;

  const handleClickSubmitAndPay = () => {
    if (!flyerInfo.s3Link) return;
    if (isVerified === false) {
      runSnackBar({
        type: "error",
        vertical: "bottom",
        msg: "For security, you must validate your email to submit the campaign.",
      });
      return false;
    }
    if (posthog) {
      posthog.capture("Clicked Campaign Submit Button", {
        event_category: "Quote",
      });
    }
    if (isPaymentSkipped) {
      deleteRedirectedCampaign(campaignId);
      onSubmit();
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOpenCouponCode = () => {
    setCouponCodeOpen(true);
  };

  const getRedirectUrlAfterSubmition = () => {
    return `${CONFIG.PLATFORM_BASE_URL}${
      user?.clientId && isClient
        ? `/dashboard/client-campaigns/${user?.clientId}/${isDM ? "dm" : isD2D ? "d2d" : "h2h"}`
        : ""
    }`;
  };

  const getCouponCode = () => {
    if (!showCoupon) {
      return undefined;
    }

    return (
      <div className={cn(classes.couponCodeWrapper, "mob-coupon-code-wrapper")}>
        {couponCodeOpen ? (
          <CouponCode
            stripeCouponCode={stripeCouponCode}
            errorMessage={couponErrorMessage}
            setCouponErrorMessage={setCouponErrorMessage}
            amountOff={amountOff}
            percentOff={percentOff}
            isDraft={!isSubmitted}
            currencySign={currencySign}
            isUSClient={isUS}
            onApply={onApplyCouponCode}
          />
        ) : (
          <span
            className={cn(classes.link, classes.couponCodeLink, "mob-coupon-triger")}
            onClick={handleOpenCouponCode}
            cypress_id={"couponCodeLinkQ"}
          >
            I have a coupon code
          </span>
        )}
      </div>
    );
  };

  return (
    <Box className={classes.quoteWrapper} style={{ overflow: "auto" }}>
      <Helmet>
        <title>Submit</title>
      </Helmet>
      <Modal
        open={isModalOpen}
        handleClose={handleCloseModal}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        onSubmit={onSubmit}
        loading={loading}
      />

      <ConfirmPopup
        isOpen={isSubmitConfirmationModalOpen}
        onClose={() => setIsSubmitConfirmationModalOpen(false)}
        loading={false}
        onSave={() => {
          window.location.replace(getRedirectUrlAfterSubmition());
        }}
        hideCancelButton
        submitAutoWidth
        title="Campaign submitted!"
        body={
          <Box>
            <p>Thank you for submitting this campaign!</p>
            <p>You don't need to do anything at this stage.</p>
            <p>We will review the campaign details and get back to you as soon as possible.</p>
            <br />
            <p>
              If you have any questions in the meantime or want to make a change, please reach out to us on{" "}
              <a href="mailto:support@oppizi.com" style={{ color: "#EE4360", fontWeight: "bold" }}>
                support@oppizi.com
              </a>
              .
            </p>
          </Box>
        }
        customConfirmText="Return to dashboard"
      />
      <Header
        tabs={isDM ? EDDM_NAVIGATION : NAVIGATION}
        activeTabIndex={isSubmitted ? 5 : 4}
        headerActions={headerActions}
        isSubmitted={isSubmitted}
        hideBackButton={hideBackButton}
        isDM={isDM}
      />
      <div className={classes.mainWrapper} style={{ width: "calc(100vw - 32px)" }}>
        <div className={cn(classes.pagesWrapper, "mob-pages-wrapper")}>
          <div className={cn(classes.page, "mob-page")}>
            <div>
              <div className={classes.title} cypress_id={"quoteSpecificsTableTitle"}>
                Campaign Specifics
              </div>
              <div className={cn(classes.row)}>
                <div className={cn(classes.text, "mob-text")} cypress_id={"campaignNameTitleQS"}>
                  Campaign name
                </div>
                <div className={cn(classes.text, "mob-text")} cypress_id={"campaignNameValueQS"}>
                  {campaignName}
                </div>
              </div>

              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"cityNameTitleQS"}>
                    City
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"cityNameValueQS"}>
                    {cityName}
                  </div>
                </div>
              )}

              <div className={cn(classes.row)}>
                <div className={cn(classes.text)} cypress_id={"channelTitleQS"}>
                  Channel
                </div>
                <div className={cn(classes.text, "mob-text")} cypress_id={"channelValueQS"}>
                  {USER_CAMPAIGN_ENUM_TYPES[channel]}
                </div>
              </div>
              {isDM ? (
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"areasOrDistributorsTitle"}>
                    Recipients
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"areasDistributorsValueAmountQS"}>
                    {flyersAmount}
                  </div>
                </div>
              ) : (
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"areasOrDistributorsTitle"}>
                    {isD2D ? "Areas " : "Distributors "}
                  </div>
                  <div className={cn(classes.text)} cypress_id={"areasDistributorsValueAmountQS"}>
                    {distribution?.quantity}
                  </div>
                </div>
              )}

              {displayOnCondition(
                isDM && couponTypeDB,
                <div className={classes.row}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"channelTitleQS"}>
                    Coupon type
                  </div>
                  <div className={cn(classes.text)} cypress_id={"channelValueQS"}>
                    {capitalize(couponTypeDB)}
                  </div>
                </div>
              )}

              {displayOnCondition(
                isDM && couponTypeDB,
                <div className={classes.row}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"channelTitleQS"}>
                    Coupon name
                  </div>
                  <div className={cn(classes.text)} cypress_id={"channelValueQS"}>
                    {couponTypeDB === "static"
                      ? staticCouponCodeDB
                      : couponTypeDB === "dynamic"
                      ? dynamicCouponCodeDB.name
                      : "-"}
                  </div>
                </div>
              )}

              <div className={cn(classes.flex, classes.cityRow)}>
                <div className={cn(classes.flex, classes.flex_1)} />
                <div className={cn(classes.flex, classes.flex_1)}>
                  <div className={cn(classes.flex_1)}>
                    {internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT ? (
                      <span onClick={toAudience} className={classes.link} cypress_id={"editAudienceLinkQS"}>
                        {`Edit ${isDM ? "Segments" : "Audience"}`}
                      </span>
                    ) : null}
                  </div>
                  <div className={cn(classes.flex_1, "display-none")} />
                </div>
              </div>

              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyersTitleQS"}>
                    Flyers
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyersAmountValueQS"}>
                    {printing?.quantity}
                  </div>
                </div>
              )}

              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"campaignDurationTitleQS"}>
                    Campaign duration
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"campaignDurationValueQS"}>
                    {campaignDuration} week{campaignDuration !== 1 ? "s" : ""}
                  </div>
                </div>
              )}

              <div className={cn(classes.row)}>
                <div className={cn(classes.text, "mob-text")} cypress_id={"campaignLaunchDateTitleQS"}>
                  Campaign launch date
                </div>
                <div className={cn(classes.text, "mob-text")} cypress_id={"campaignLaunchDateValueQS"}>
                  {customDateFormat(startDate.value, isUS)}
                </div>
              </div>

              {isShowWrongDatesModal ? (
                <AlertInvalidMissions
                  defaultCampaignLimits={defaultCampaignLimits}
                  wrongDates
                  handleDatesChange={handleDatesChange}
                  modalType={isDM}
                />
              ) : null}
              <div className={cn(classes.row)}>
                <div className={cn(classes.text, "mob-text")} cypress_id={"flyerTypeTitleQS"}>
                  {isDM ? "Mail piece format" : "Flyer type"}
                </div>
                <div className={cn(classes.text)} cypress_id={"flyerTypeValueQS"}>
                  {isDM ? DMDocumentLabels[flyerType] : DocumentNames[flyerType]}
                  {isSkipEnabled && notExistingDesignFile && !isDM && DocumentNames[flyerType] === DocumentNames.A6 ? (
                    <span className={classes.default}>{"(default)"}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerWeightTitleQS"}>
                    Flyer weight
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerWeightValueQS"}>
                    {`${flyerWeight} GSM`}
                  </div>
                </div>
              )}

              {isSkipEnabled && notExistingDesignFile ? (
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerDesignTitleQS"}>
                    Artwork name
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerDesignValueQS"}>
                    <span className={cn(classes.noFile)}>No file uploaded</span>
                  </div>
                </div>
              ) : (
                <div className={cn(classes.row)}>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerDesignTitleQS"}>
                    Flyer design
                  </div>
                  <div className={cn(classes.text, "mob-text")} cypress_id={"flyerDesignValueQS"}>
                    <span onClick={handleViewDesignClick} className={cn(classes.link)} cypress_id={"viewDesignLinkQS"}>
                      View design
                    </span>
                  </div>
                </div>
              )}
              {isSkipEnabled && notExistingDesignFile && <UploadFlyerSection classes={classes} isDM={isDM} />}
              <div className={classes.line} />
              <div className={classes.containerSubmitterPrint}>
                <div className={cn(classes.text)} cypress_id={"submittedByTitleQS"}>
                  {isSubmitted ? "Submitted by:" : "Submitter:"}
                  <span className={classes.marginL4} cypress_id={"submitterValueQS"}>
                    {isSubmitted ? submittedBy : submitter}
                  </span>
                </div>
                <div className={cn(classes.printWrap, "display-none ")}>
                  <div onClick={() => handlePrint()}>
                    <PrintIcon />
                    <span className={classes.underline} cypress_id={"printIconQS"}>
                      Print
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(classes.page, "second-child mob-page")}>
            <div>
              <div className={classes.flex}>
                <div className={classes.title} cypress_id={"quoteTableTitle"}>
                  Quote
                </div>
                <div className={cn(classes.text, classes.quote)} cypress_id={"quoteValueQ"}>
                  {quote}
                </div>
              </div>
              <div className={cn(classes.row, "mob-row", classes.detailsProvided)}>Please review details provided:</div>
              <div className={cn(classes.marginBottom_4, classes.flex, classes.center)}>
                <div className={classes.campaignName} cypress_id={"campaignNameValueQ"}>
                  {campaignName}
                </div>
                <div cypress_id={"campaignStatusValueQ"}>{renderStatus(status)}</div>
              </div>
              <div className={cn(classes.row)}>
                <div cypress_id={"companyNameTitleQ"}>Company name</div>
                <div cypress_id={"companyNameValueQ"}>{clientName}</div>
                <div />
              </div>
              <div className={cn(classes.row)}>
                <div cypress_id={"dateTitleQ"}>Date</div>
                <div cypress_id={"dateValueQ"}>
                  {status === READABLE_CAMPAIGN_INTERNAL_STATUSES.DRAFT
                    ? formatDate(new Date(), "MMM d, yyyy")
                    : formatDate(+updatedAt, "MMM d, yyyy")}
                </div>
                <div />
              </div>
              <div className={cn(classes.table)}>
                <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                  <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"descriptionColumnTitleQ"}>
                    Description
                  </div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)} cypress_id={"quanityColumnTitleQ"}>
                    Quantity
                  </div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)} cypress_id={"unitRateColumnTitleQ"}>
                    Unit rate
                  </div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)} cypress_id={"totalColumnTitleQ"}>
                    Total
                  </div>
                </div>
                {displayOnCondition(
                  isDM,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"distributionCostRowTitleQ"}>
                      Mailings
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionQuantityQ"}>
                      {flyersAmount}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionUnitRateQ"}>
                      {convertNumber({
                        number: costPerFlyer,
                        currency: currencySign,
                        fixed: 4,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionTotalQ"}>
                      {convertNumber({
                        number: subtotal,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  distribution,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"distributionCostRowTitleQ"}>
                      Distribution Cost
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionQuantityQ"}>
                      {distribution?.quantity}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionUnitRateQ"}>
                      {convertNumber({
                        number: distribution?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"distributionTotalQ"}>
                      {convertNumber({
                        number: distribution?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  printing,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"printingCostRowTitleQ"}>
                      Printing Cost
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"printingCostQuantityQ"}>
                      {printing?.quantity}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"printingCostUnitRateQ"}>
                      {currencySign}
                      {toFixed3(printing?.rate || 0)}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"printingCostTotalQ"}>
                      {convertNumber({
                        number: printing?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  travel,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"travelCostRowTitleQ"}>
                      Travel Cost
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"travelCostQuantityQ"}>
                      {travel?.quantity}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"travelCostUnitRateQ"}>
                      {convertNumber({
                        number: travel?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"travelCostTotalQ"}>
                      {convertNumber({
                        number: travel?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  permit,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"permitCostRowTitleQ"}>
                      Permit Cost
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"permitCostQuantityQ"}>
                      {permit?.quantity}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"permitCostUnitRateQ"}>
                      {convertNumber({
                        number: permit?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"permitCostTotalQ"}>
                      {convertNumber({
                        number: permit?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {outfit?.total ? (
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)} cypress_id={"outfitCostRowTitleQ"}>
                      Outfit Cost
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"outfitCostQuantityQ"}>
                      {outfit?.quantity}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"outfitCostUnitRateQ"}>
                      {convertNumber({
                        number: outfit?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"outfitCostTotalQ"}>
                      {convertNumber({
                        number: outfit?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                ) : null}
                <div className={cn(classes.tableRow, "mob-table-parent-2-child")}>
                  <div className={cn(classes.lastRows, classes.bold)}>
                    <span cypress_id={"subtotalTitleQ"} className="margin-left">
                      Subtotal
                    </span>
                  </div>
                  <div className={cn(classes.flex_1, classes.center)} cypress_id={"subtotalValueQ"}>
                    {convertNumber({
                      number: subtotal,
                      currency: currencySign,
                      fixed: 2,
                      isUSClient: isUS,
                    })}
                  </div>
                </div>
                {getCouponCode()}
                <div className={cn(classes.tableRow, "mob-table-parent-error-child", { [classes.taxRow]: isUS })}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div className={cn(classes.lastRows, classes.bold)}>
                      <span cypress_id={"taxTitleQ"} className="margin-left">
                        {isUS && !isDM ? "Sales Tax" : "Tax"}
                      </span>
                    </div>
                    <div className={cn(classes.flex_1, classes.center)} cypress_id={"taxValueQ"}>
                      {convertNumber({
                        number: tax,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                  {isUS && !isDM && (
                    <span className={cn(classes.salesTaxesWarn, "mob-sales-taxes-ml-10")}>
                      {
                        "Please note that the displayed tax price is an estimate. The final amount will be confirmed upon invoice generation."
                      }
                    </span>
                  )}
                </div>
                <div className={cn(classes.row, classes.grandTotalWrapper)}>
                  <div className={cn(classes.flex_1, "display-none")} />
                  <div className={cn(classes.grandTotalSubContainer_1, "margin-bottom")}>
                    <div className={cn(classes.grandTotalSubContainer_2, "grand-total")}>
                      <div className={cn(classes.grandTotal, classes.bold)} cypress_id={"grandTotalTitleQ"}>
                        Grand Total:
                      </div>
                      <div
                        className={cn(classes.grandTotalCost, classes.bold, classes.center)}
                        cypress_id={"grandTotalValueQ"}
                        style={{ textAlign: "right" }}
                      >
                        {convertNumberRounded({
                          number: totalCost,
                          currency: currencySign,
                          fixed: 2,
                          isUSClient: isUS,
                        })}
                      </div>
                    </div>
                    {tax > 0 && <span className={classes.taxText}>{isUK ? "*incl. VAT" : "*incl. taxes"}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className={cn(classes.buttonWrapper, "justify-content")} cypress_id={"submitAndPayBtn"}>
              <SubmitAndPayButton
                internalStatus={internalStatus}
                disabled={loading || startDate.error || !flyerInfo.s3Link}
                classes={classes}
                loading={loading}
                isPaymentSkipped={isPaymentSkipped}
                handleClickSubmitAndPay={handleClickSubmitAndPay}
                isNotDesign={notExistingDesignFile}
                isSkipEnabled={isSkipEnabled}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div style={{ display: "none" }} className={classes.mainWrapper}>
        <div className={cn(classes.pagesWrapper, "mob-pages-wrapper")}>
          <div className={cn(classes.page, "mob-page")}>
            <div ref={firstPageRef}>
              <div className={classes.title}>Campaign Specifics</div>
              <div className={cn(classes.row, "mob-row")}>
                <div className={cn(classes.text)}>Campaign name</div>
                <div className={cn(classes.text)}>{campaignName}</div>
              </div>
              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>City</div>
                  <div className={cn(classes.text)}>{cityName}</div>
                </div>
              )}
              <div className={cn(classes.row, "mob-row")}>
                <div className={cn(classes.text)}>Channel</div>
                <div className={cn(classes.text)}>{USER_CAMPAIGN_ENUM_TYPES[channel]}</div>
              </div>

              {isDM ? (
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>{"Recipients"}</div>
                  <div className={cn(classes.text)}>{flyersAmount}</div>
                </div>
              ) : (
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>{isD2D ? "Areas " : "Distributors "}</div>
                  <div className={cn(classes.text)}>{distribution?.quantity}</div>
                </div>
              )}

              <div className={cn(classes.flex, classes.cityRow)}>
                <div className={cn(classes.flex, classes.flex_1, "display-none")} />
                <div className={cn(classes.flex, classes.flex_1)}>
                  <div className={cn(classes.flex_1)}>
                    {internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT ? (
                      <span onClick={toAudience} className={classes.link}>
                        {`Edit ${isDM ? "Segments" : "Audience"}`}
                      </span>
                    ) : null}
                  </div>
                  <div className={cn(classes.flex_1)} />
                </div>
              </div>
              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>Flyers</div>
                  <div className={cn(classes.text)}>{printing?.quantity}</div>
                </div>
              )}
              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>Campaign duration</div>
                  <div className={cn(classes.text)}>
                    {campaignDuration} week{campaignDuration !== 1 ? "s" : ""}
                  </div>
                </div>
              )}

              <div className={cn(classes.row, "mob-row")}>
                <div className={cn(classes.text)}>Campaign launch date</div>
                <div className={cn(classes.text)}>{customDateFormat(startDate.value, isUS)}</div>
              </div>

              <div className={cn(classes.row, "mob-row")}>
                <div className={cn(classes.text)}>Flyer type</div>
                <div className={cn(classes.text)}>{DocumentNames[flyerType]}</div>
              </div>
              {displayOnCondition(
                !isDM,
                <div className={cn(classes.row, "mob-row")}>
                  <div className={cn(classes.text)}>Flyer weight</div>
                  <div className={cn(classes.text)}>{flyerWeight}</div>
                </div>
              )}

              <div className={cn(classes.row, "mob-row")}>
                <div className={cn(classes.text)}>Flyer design</div>
                <div className={cn(classes.link)}>View design</div>
              </div>
              <div className={classes.line} />
              <div className={cn(classes.text)}>
                {isSubmitted ? "Submitted by:" : "Submitter:"}
                <span className={classes.marginL4}>{isSubmitted ? submittedBy : submitter}</span>
              </div>
            </div>
          </div>
          <div className={cn(classes.page, "mob-page")}>
            <div ref={secondPageRef}>
              <div className={classes.flex}>
                <div className={classes.title}>Quote</div>
                <div className={cn(classes.text, classes.quote)}>{quote}</div>
              </div>
              <div className={cn(classes.row, "mob-row", classes.detailsProvided)}>Please review details provided:</div>
              <div className={cn(classes.flex, classes.center)}>
                <div className={classes.campaignName}>{campaignName}</div>
                <div>{renderStatus(status)}</div>
              </div>
              <div className={cn(classes.row, "mob-row")}>
                <div>Company name</div>
                <div>{clientName}</div>
                <div />
              </div>
              <div className={cn(classes.row, "mob-row")}>
                <div>Date</div>
                <div>
                  {status === READABLE_CAMPAIGN_INTERNAL_STATUSES.DRAFT
                    ? formatDate(new Date(), "MMM d, yyyy")
                    : formatDate(+updatedAt, "MMM d, yyyy")}
                </div>
                <div />
              </div>
              <div className={cn(classes.table)}>
                <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                  <div className={cn(classes.flex_1_5, classes.bold)}>Description</div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)}>Quantity</div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)}>Unit rate</div>
                  <div className={cn(classes.flex_1, classes.bold, classes.center)}>Total</div>
                </div>

                {displayOnCondition(
                  isDM,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Mailings</div>
                    <div className={cn(classes.flex_1, classes.center)}>{flyersAmount}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: costPerFlyer,
                        currency: currencySign,
                        fixed: 3,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: subtotal,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  distribution,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Distribution Cost</div>
                    <div className={cn(classes.flex_1, classes.center)}>{distribution?.quantity}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: distribution?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: distribution?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  printing,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Printing Cost</div>
                    <div className={cn(classes.flex_1, classes.center)}>{printing?.quantity}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {currencySign}
                      {toFixed3(printing?.rate || 0)}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: printing?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  travel,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Travel Cost</div>
                    <div className={cn(classes.flex_1, classes.center)}>{travel?.quantity}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: travel?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: travel?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {hideOnNull(
                  permit,
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Permit Cost</div>
                    <div className={cn(classes.flex_1, classes.center)}>{permit?.quantity}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: permit?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: permit?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                )}
                {outfit?.total ? (
                  <div className={cn(classes.tableRow, "mob-table-parent-4-child")}>
                    <div className={cn(classes.flex_1_5, classes.bold)}>Outfit Cost</div>
                    <div className={cn(classes.flex_1, classes.center)}>{outfit?.quantity}</div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: outfit?.rate,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                    <div className={cn(classes.flex_1, classes.center)}>
                      {convertNumber({
                        number: outfit?.total,
                        currency: currencySign,
                        fixed: 2,
                        isUSClient: isUS,
                      })}
                    </div>
                  </div>
                ) : null}
                <div className={cn(classes.tableRow, "mob-table-parent-3-child")}>
                  <div className={cn(classes.flex_1_5)} />
                  <div className={cn(classes.flex_1, classes.bold)}>Subtotal</div>
                  <div className={cn(classes.flex_1, classes.center)}>
                    {convertNumber({
                      number: subtotal,
                      currency: currencySign,
                      fixed: 2,
                      isUSClient: isUS,
                    })}
                  </div>
                </div>
                {getCouponCode()}
                <div className={cn(classes.tableRow, "mob-table-parent-3-child")}>
                  <div className={cn(classes.flex_1_5)} />
                  <div className={cn(classes.flex_1, classes.bold)}>Tax</div>
                  <div className={cn(classes.flex_1, classes.center)}>
                    {convertNumber({
                      number: tax,
                      currency: currencySign,
                      fixed: 2,
                      isUSClient: isUS,
                    })}
                  </div>
                </div>
                <div className={cn(classes.row, classes.grandTotalWrapper)}>
                  <div className={cn(classes.flex_1)} />
                  <div className={cn(classes.grandTotalSubContainer_1, "margin-bottom")}>
                    <div className={classes.grandTotalSubContainer_2}>
                      <div className={cn(classes.grandTotal, classes.bold)}>Grand Total:</div>
                      <div className={cn(classes.grandTotalCost, classes.bold, classes.center)}>
                        {convertNumberRounded({
                          number: totalCost,
                          currency: currencySign,
                          fixed: 2,
                          isUSClient: isUS,
                        })}
                      </div>
                    </div>
                    {tax > 0 && <span className={classes.taxText}>{isUK ? "*incl. VAT" : "*incl. taxes"}</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withStyles(style)(QuoteView);
